
const RenderPackages = (packageList) => {
    if (packageList === undefined || packageList === null) return
        let mdText = "## Packages\n"

    function createMarkdownTextForPackage(packageJson) {
            if (packageJson === null || packageJson === undefined) return
        return `- ${packageJson.name} _(${packageJson.version})_\n`;
    }

    packageList.map(p => (
        mdText += createMarkdownTextForPackage(p)
    ))
  return mdText
 }


export default RenderPackages;