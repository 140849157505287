import {Col, Row} from "react-bootstrap";
import {getAction} from "../functions/Actions";

const PropertyRenderer = ({currentProperty}) => {

    let action = getAction(currentProperty)

    return (
        <>
            <Col className={"container d-flex flex-column"}>

                <Row className={"text-capitalize fw-bold"}>
                    <>{action.friendlyName}</>
                </Row>
                <Row className={"small text-muted mt-4"} style={{minHeight: 150 + "px"}}>
                    {action.descriptionHtml}
                </Row>

                <Row className={"small text-muted mt-4"}>
                    Attributes
                </Row>
                <Row className={"small text-muted mt-4"}>
                    Returns
                </Row>
            </Col>
        </>
    )
}

export default PropertyRenderer;