import {getCommandNodesFromNode, getRepositoryFriendlyName} from "../../functions/Nodes";

const RenderSubtasks = (nodes) => {
    let commandName = 'runTask'
    let mdText = "## Subtasks\n"
    let steps = []
    for (const node in nodes){

        let nodeSteps = getCommandNodesFromNode(nodes[node], commandName)
        steps = [...steps, ...nodeSteps]

        for (const childNode in node.children) {

            let childNodeSteps = getCommandNodesFromNode(node.children[childNode], commandName)
            steps = [...steps, ...childNodeSteps]
        }
        for (const childNode in node.branches) {

            let childNodeSteps = getCommandNodesFromNode(node.branches[childNode], commandName)
            steps = [...steps, ...childNodeSteps]
        }
    }
    let taskbotDistinctList = []
    for (const stepsKey in steps) {
        let pathName = getRepositoryFriendlyName(steps[stepsKey].attributes[0].value.taskbotFile.string)
        let pathParts = pathName.split('/')
        let subTaskName = pathParts[pathParts.length-1]

        if (!taskbotDistinctList.includes(subTaskName)) {
            taskbotDistinctList.push(subTaskName)
            mdText += `1. ${subTaskName} _(${pathName})_\n`
        }
    }

  return mdText
 }


export default RenderSubtasks;
