const Home = () => {
    return <>
        <section className="fs-5 mt-5 container">
            <p>Welcome to my Automation Anywhere Dev Journey!</p>
            <p>As I navigate the world of Automation Anywhere, I've compiled my insights, challenges, and breakthroughs into this space - my personal notes. Whether you're a seasoned developer or just starting out, join me on this journey
                of exploration and learning.</p>
            <p>Here, you'll find a collection of my thoughts, essential tips, and real-world gotchas. It's a place where I document efficient code structuring practices and quick reference guides, serving as a reminder for myself and a
                helpful resource for anyone facing similar challenges.</p>
            <p>Come along as I share quick solutions to common problems, reveal best practices for automation workflows, and provide practical code snippets for seamless script development. This is not just a collection of guides; it's a
                reflection of my experiences, trials, and triumphs in the world of Automation Anywhere.</p>
            <p>Ready to elevate your automation skills? Dive into my Automation Anywhere Guides and let's make our automation projects smoother, more reliable, and efficient together.</p>
        </section>
    </>;
};

export default Home;