import {getCommandNodesFromNode} from "../../functions/Nodes";

const RenderSteps = (nodes) => {
    if (nodes === undefined || nodes === null) return
    let mdText = "## Steps\n"


    let steps = []
    for (const node in nodes){

        let nodeSteps = getCommandNodesFromNode(nodes[node], 'step')
        steps = [...steps, ...nodeSteps]

        for (const childNode in node.children) {
            let childNodeSteps = getCommandNodesFromNode(node.children[childNode], 'step')
            steps = [...steps, ...childNodeSteps]
        }

        for (const childNode in node.branches) {
            let childNodeSteps = getCommandNodesFromNode(node.branches[childNode], 'step')
            steps = [...steps, ...childNodeSteps]
        }
    }
    function createMarkdownTextForPackage(stepNode) {
        let stepDescription = Object.values(stepNode.attributes[0].value)[1]
        return `1. ${stepDescription}\n`;
    }

    steps.map(p => (
        mdText += createMarkdownTextForPackage(p)
    ))

  return mdText
 }


export default RenderSteps;