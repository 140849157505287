
const RenderVariables = (variableList, title) => {
    if (variableList === undefined || variableList === null) return


   let mdText = `## ${title}\n`
    const createMarkdownTextForVariable = (variableJson) => {
        if (variableJson === null || variableJson === undefined) return
        let readOnlyMd = variableJson.readOnly ? `Readonly ` : ``
        let titleMd = `##### ${variableJson.name} \n_Type: ${readOnlyMd}${variableJson.type.toLowerCase()}_`

        let defaultValue = Object.values(variableJson?.defaultValue)[1].toString()
        if (defaultValue === ""){
            defaultValue = `Empty ${Object.values(variableJson?.defaultValue)[0].toString().toLowerCase()}`
        }

        let defaultValueMd = `\n**Default Value** \n\n${defaultValue}`

        let descriptionTitleMd = `\n\n**Description**`
        let descriptionMd = `\n${variableJson.description}`

        return `${titleMd}
        ${defaultValueMd}
        
        ${descriptionTitleMd}
        ${descriptionMd}\n\n`
    }


    variableList.map(v => (
        mdText += createMarkdownTextForVariable(v)
    ))

    if (variableList.length === 0) {
        mdText += "None\n"
    }
  return mdText
 }


export default RenderVariables;