import {useEffect, useState} from "react";
import RenderVariables from "./RenderVariables";
import RenderPackages from "./RenderPackages";
import RenderSteps from "./RenderSteps";
import ReactMarkdown from "react-markdown";
import RenderSubtasks from "./RenderSubtasks";

const AutoDocumentContainer = ({jsonData}) => {

    const [loadedData, setLoadedData] = useState(jsonData);
    const [titleName, setTitleName] = useState(null);
    useEffect(() => {
        const fetchData = () => {
            try {
                const storedData = localStorage.getItem("json_data");
                const storedTitle = localStorage.getItem("json_title");

                if (storedData) {
                    setLoadedData(JSON.parse(storedData));
                    setTitleName(storedTitle)
                }
            } catch (error) {
                console.log(error)
            }
        };
        fetchData();

    }, []);
    let inputVariables = loadedData?.variables.filter(v => v.input === true)
    let outputVariables = loadedData?.variables.filter(v => v.output === true)

    let mdText = ""

    mdText += RenderSteps(loadedData?.nodes)
    mdText += RenderSubtasks(loadedData?.nodes)
    mdText += RenderVariables(inputVariables, "Input Variables")
    mdText += RenderVariables(outputVariables, "Output Variables")
    mdText += RenderPackages(loadedData?.packages)


    function handleOnClick() {
        navigator.clipboard.writeText(mdText)
    }

    return (
        <div className={"container"}>
            <div className={"d-flex justify-content-between"}>
                <h1 style={{marginBottom: -24 + "px"}}>{titleName}</h1>
                <button className={"btn"} onClick={handleOnClick}><i className="bi bi-copy"></i> Copy</button>
            </div>

            <ReactMarkdown className={"mt-5"}>
                {mdText}
            </ReactMarkdown>
        </div>
    )
}


export default AutoDocumentContainer;