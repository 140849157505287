import AutoDocumentContainer from "../components/autodoc/AutoDocumentContainer";

const AutoDocument = () => {
  return (
   <>
        <AutoDocumentContainer />
   </>
  )
 }


export default AutoDocument;