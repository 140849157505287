const References = () => {
    return (
        <>
            <section id="references" className="my-5">
                <div className="text-center">
                    <h2 className="text-center">References</h2>
                    <p className="lead text-center">This site is powered by <strong>Open Source</strong> and I am grateful to the open-source community and the creators of the following libraries:</p>

                    <ul className="list-unstyled">
                        <li className="mb-3">
                            <strong className={"h5"}>Hosted on GitHub Pages</strong>
                            <ul className="list-unstyled">
                                <li>
                                    <a href="https://pages.github.com/" rel={"noopener noreferrer"} target="_blank">Github Pages</a>
                                </li>
                                <li>
                                    <a href="https://docs.github.com/en/pages" rel={"noopener noreferrer"} target="_blank">Documentation</a>
                                </li>
                            </ul>
                        </li>
                        <li className="mb-3">
                            <strong className={"h5"}>Using React Framework</strong>
                            <ul className="list-unstyled">
                                <li><a href="https://reactjs.org/" rel={"noopener noreferrer"} target="_blank">React JS</a></li>
                                <li><a href="https://github.com/facebook/react" rel={"noopener noreferrer"} target="_blank">Github</a></li>
                            </ul>
                        </li>
                        <li className="mb-3">
                            <strong className={"h5"}>Routing made possible by React-router</strong>
                            <ul className="list-unstyled">
                                <li><a href="https://reactrouter.com/web/guides/quick-start" rel={"noopener noreferrer"} target="_blank">Quick Start</a></li>
                                <li><a href="https://github.com/remix-run/react-router" rel={"noopener noreferrer"} target="_blank">Github</a></li>
                            </ul>
                        </li>
                        <li className="mb-3">
                            <strong className={"h5"}>Designed using the Bootstrap framework</strong>
                            <ul className="list-unstyled">
                                <li><a href="https://getbootstrap.com" rel={"noopener noreferrer"} target="_blank">Get Bootstrap</a></li>
                                <li><a href="https://github.com/twbs/bootstrap" rel={"noopener noreferrer"} target="_blank">Github</a></li>
                                <li><a href="https://github.com/twbs/bootstrap/blob/v5.3.2/site/content/docs/5.3/about/license.md" rel={"noopener noreferrer"} target="_blank">License</a></li>
                                <li><a href="https://github.com/react-bootstrap/react-bootstrap" rel={"noopener noreferrer"} target="_blank">React Bootstrap</a></li>
                            </ul>
                        </li>

                        <li className="mb-3">
                            <strong className={"h5"}>Charts are rendered using Mermaid.js</strong>
                            <ul className="list-unstyled">
                                <li><a href="https://mermaid-js.github.io/" rel={"noopener noreferrer"} target="_blank">Mermaid JS</a></li>
                                <li><a href="https://github.com/mermaid-js/mermaid" rel={"noopener noreferrer"} target="_blank">Github</a></li>
                            </ul>
                        </li>
                        <li className="mb-3">
                            <strong className={"h5"}>Markdown displayed with React-markdown</strong>
                            <ul className="list-unstyled">
                                <li><a href="https://github.com/remarkjs/react-markdown" rel={"noopener noreferrer"} target="_blank">Github</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </section>
        </>
    )
}


export default References;